import React from 'react';
import Header from '../components/Header/Header'
import GlobalStyle from "../GlobalStyles"
import Footer from "../components/Footer/Footer"
import { PageTitleWrapper, PageTitle } from "../components/PageTitle/PageTitle"
import ContentWithImage from "../components/ContentWithImage/ContentWithImage"
import { Paragraph, Image } from "../components/ContentWithImage/ContentWithImage"
import Helmet from "react-helmet"

const BusinessEntities = () => (
  <>
    <GlobalStyle />
    <Helmet>
      <meta name="viewport"
            content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta charSet="utf-8" />
      <title>Kancelaria Merda</title>
      <link rel="canonical" href="https://kancelaria-merda.pl/" />
    </Helmet>
    <Header />
    <PageTitleWrapper>
      <PageTitle>Podmioty gospodarcze</PageTitle>
    </PageTitleWrapper>
    <ContentWithImage>
      <Paragraph>
        Znając potrzeby przedsiębiorców i rynku usług prawniczych została przygotowana niezwykle
        elastyczna oferta, która pozwoli dostosować się do indywidualnych potrzeb każdego Klienta.
        Kancelaria zajmuje się prowadzeniem spraw we wszystkich dziedzinach prawa: m.in. prawo cywilne,
        gospodarcze, administracyjne, prawo pracy, sprawy zus-owskie, budowlane, zamówienia publiczne,
        prawo spółdzielcze czy też prawo ochrony środowiska. Klient może dostosować formę i zakres
        obsługi prawnej oferowanej przez Kancelarię do własnych potrzeb i wymagań. Dzięki temu Kancelaria
        jest w stanie bez problemu dopasować się do wymogów konkretnego przedsiębiorcy, a jednocześnie
        zasady współpracy są jasne i klarowne. Jeżeli są Państwo zainteresowani współpracą z Kancelarią lub
        też szczegółami oferty proszę o kontakt telefoniczny lub mailowy.
      </Paragraph>
      <Image src="/BusinessEntitiesImage.png" />
    </ContentWithImage>
    <Footer />
  </>
)

export default BusinessEntities;