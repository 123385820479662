import React from 'react'
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  margin: 70px 0;
  display: flex;
  justify-content: center;
  
  @media (min-width: 992px) {
      padding: 0 135px;
  }
  
  @media (max-width: 992px) {
    margin: 0;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    padding: 34px;
  }
`

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 35px;
  color: #000;
  font-family: "Work Sans", sans-serif;
  width: 50%;
  padding-right: 100px;
  text-align: justify;
  font-weight: 300;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 14px;
    line-height: 24px;
  }
  
  @media (max-width: 992px) {
    font-size: 12px;
    width: auto;
    padding: 0;
    line-height: 22px;
  }
`

export const Image = styled.img`
  width: 50%;
  
  @media (max-width: 992px) {
    margin-bottom: 20px;
    width: 100%;
    height: 300px;
    padding: 0;
  }
`

const ContentWithImage = ({children}) => (
  <Wrapper>
    <InnerWrapper>
      {children}
    </InnerWrapper>
  </Wrapper>
)

export default ContentWithImage;